import dynamic from 'next/dynamic';
import { UmbracoTypes } from '~/lib/data-contract';
import { RawHtml } from '~/shared/components/RawHtml';
import { ModuleHeader } from '~/templates/blocks/shared/ModuleHeader';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { ModuleContainer } from '../../shared/ModuleContainer';
import { StyledColumns, StyledM100RichText } from './styled';

type M100RichTextProps = UmbracoTypes.IM100RichTextModule;

const CookiePolicyScript = dynamic<unknown>(
    () => import('~/shared/scripts').then((mod) => mod.CookiePolicyScript),
    { ssr: false },
);

export const M100RichText = ({
    text,
    headline,
    secondaryColumn,
    secondaryColumnScripts,
    ...rest
}: M100RichTextProps) => {
    const { textColor } = useModuleTheme(rest);

    const scriptIsCookiePolicy =
        secondaryColumnScripts === UmbracoTypes.RichTextScriptTypes.CookiePolicy;

    return (
        <ModuleContainer hasGutter {...rest}>
            <ModuleHeader headlineText={headline} headlineVariant="display4" />

            <StyledM100RichText textColor={textColor}>
                <StyledColumns>
                    {text && <RawHtml html={text} />}

                    {secondaryColumn || scriptIsCookiePolicy ? (
                        <div>
                            {secondaryColumn && <RawHtml html={secondaryColumn} />}

                            {scriptIsCookiePolicy && <CookiePolicyScript />}
                        </div>
                    ) : null}
                </StyledColumns>
            </StyledM100RichText>
        </ModuleContainer>
    );
};
