import styled from '@emotion/styled';
import { StyledRichText } from '~/shared/components/RawHtml/styled';
import { breakpoints } from '~/theme';
import { StyledHeader } from '../../shared/ModuleHeader/styled';

export const StyledM100RichText = styled.div<{ textColor: string }>(({ textColor, theme }) => ({
    color: textColor,

    [`${StyledRichText}`]: {
        color: textColor,
    },

    [`${StyledHeader}`]: {
        marginBottom: theme.space[3],
    },
}));

export const StyledColumns = styled.div({
    [breakpoints.sm]: {
        display: 'flex',
        flexFlow: 'row',
        gap: 30,

        '>*': {
            flex: '1 1',
        },
    },
});
